import {
  registerApplication,
  start,
  navigateToUrl,
  RegisterApplicationConfig
} from 'single-spa';
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine
} from 'single-spa-layout';
import layout from './single-spa-layout.html';
import { createNanoEvents, Emitter } from 'nanoevents';
import './assets/index.css';
import { Events, TEventBusEvent, TEventBusEventsMap } from './types';

const ACCESS_TOKEN_KEY = '@spiral-portal/access_token';
const ID_TOKEN_KEY = '@spiral-portal/id_token';

// Event Bus setup
const rootEventBus: Emitter<TEventBusEventsMap> = createNanoEvents();

rootEventBus.on(Events.THEME_CHANGE, ({ data }: TEventBusEvent<string>) => {
  useTheme(data);
});

rootEventBus.on(
  Events.USER_LOGIN,
  ({
    data: { access_token, id_token }
  }: TEventBusEvent<{
    access_token: string;
    id_token: string;
  }>) => {
    if (access_token && id_token) {
      localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
      localStorage.setItem(ID_TOKEN_KEY, id_token);

      navigateToUrl(`${location.origin}/login`);
    }

    location.reload();
  }
);

rootEventBus.on(Events.USER_LOGOUT, e => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);

  const idTokenHint = localStorage.getItem(ID_TOKEN_KEY);

  localStorage.removeItem(ID_TOKEN_KEY);

  navigateToUrl(
    `${location.origin}/logout${
      idTokenHint ? `?id_token_hint=${idTokenHint}` : ''
    }`
  );
});

rootEventBus.on(Events.NAVIGATE, ({ data }: TEventBusEvent<string>) => {
  navigateToUrl(`${location.origin}${data}`);
});

// Single-spa setup
const routes = constructRoutes(layout, {
  loaders: {
    content: `<div class="app-loader"></div>`
  },
  props: {}
});

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  }
});

const layoutEngine = constructLayoutEngine({ routes, applications });

const allAppsRegistration = async () => {
  let loginToken = undefined;

  const rolesModule = await System.import('@config/roles');

  try {
    loginToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  } catch (e) {
    console.error(e);
  }

  applications.forEach((app: RegisterApplicationConfig) => {
    const registrationConfig = {
      ...app,
      customProps: {
        rootEventBus,
        loginData: {
          token: loginToken,
          expiresIn: ''
        },
        rolesConfig: rolesModule.default
      }
    };

    if (app.name === '@spiral-portal/sidebar') {
      registrationConfig.activeWhen = () =>
        !['/login', '/logout'].includes(location.pathname);
    }

    registerApplication(registrationConfig);
  });
};

allAppsRegistration();

layoutEngine.activate();

if (localStorage.getItem(ACCESS_TOKEN_KEY) && location.pathname === '/login') {
  navigateToUrl(`${location.origin}/home`);
} else if (
  !localStorage.getItem(ACCESS_TOKEN_KEY) &&
  location.pathname !== '/login'
) {
  navigateToUrl(`${location.origin}/login`);
} else if (location.pathname === '/') {
  navigateToUrl(`${location.origin}/home`);
}

start({
  urlRerouteOnly: true
});

// Theme setup
if (localStorage.getItem('SS-Portal-theme') === 'dark') {
  useTheme('dark');
} else {
  useTheme('light');
}

function useTheme(theme: string) {
  if (theme === 'light') {
    // TODO: need to remove dom usage
    document.body?.classList.add('v-theme--light');
    document.body?.classList.remove('v-theme--dark');
  } else {
    document.body?.classList.add('v-theme--dark');
    document.body?.classList.remove('v-theme--light');
  }
}
