export enum Events {
  NAVIGATE = 'navigate',
  THEME_CHANGE = 'theme:change',
  USER_LOGIN = 'user:login',
  USER_LOGOUT = 'user:logout'
}

export type TEventBusEvent<T> = {
  data: T;
  meta: {
    appName: string;
    location?: string;
    appVersion?: string;
  };
  onSuccess?: () => void;
  onError?: () => void;
};

export type TEventBusEventsMap = Record<
  Events,
  (event: TEventBusEvent<unknown>) => void
>;
